import elementComponentNameMap from "../../components/customComponents/appViewer/utils/uiRenderUtils/elementComponentNameMap"
import elementLazyLoadMap from '../../components/customComponents/appViewer/utils/uiRenderUtils/elementLazyLoadMap';

const initialState = {
    cachedUiComponents : {},
    loadedComponents : {}
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_UI_COMPONENT_CACHE':
            if(
                action.payload.elementType &&
                !state.cachedUiComponents[elementComponentNameMap[action.payload.elementType]]
            ){
                let UiObj = {};
                try{
                    // let refresh = elementLazyLoadMap[elementComponentNameMap[action.payload.elementType]];
                    // if(!refresh || typeof refresh !== 'function'){
                    //     console.log('missing element', action.payload.elementType)
                    // }
                    // let elementComponent = refresh();
                    // console.log('TRIGGERRRRREEEEEDDDDDD', action.payload.elementType)
                    // if(elementComponent._payload._status === 2){
                    //     console.log('import component failed', action.payload.elementType, elementComponent);
                    //     // const currentUrl = window.location.href;
                    //     // const urlWithCacheBuster = currentUrl + (currentUrl.includes('?') ? '&' : '?') + 'cachebuster=' + Date.now();
                    //     // window.location.href = urlWithCacheBuster;
                    //     window.location.reload();
                    // }
                    UiObj[elementComponentNameMap[action.payload.elementType]] = action.payload.elementComponent;
                }catch(e){
                    console.log('/uiComponentReducer catch block error', e);
                    // const currentUrl = window.location.href;
                    // const urlWithCacheBuster = currentUrl + (currentUrl.includes('?') ? '&' : '?') + 'cachebuster=' + Date.now();
                    // window.location.href = urlWithCacheBuster;
                    window.location.reload();
                }

                return {
                    ...state,
                    cachedUiComponents : {
                        ...state.cachedUiComponents,
                        ...UiObj
                    }
                }
            }else{
                return state;
            }
        case 'TRIGGER_COMPONENT_LOAD':
            return {
                ...state,
                loadedComponents : {
                    ...state.loadedComponents,
                    [elementComponentNameMap[action.payload.elementType]] : true
                }
            }
        default:
            return state;
    }
}