import simpleScriptParser from './simpleScript/simpleScriptParser';
import setMappedVariable from './mappingFunction/setMappedVariable';
import ifElseParser from './functionTypeParser/ifElseParser';
import forLoopParser from './functionTypeParser/forLoopParser';
import whileLoopParser from './functionTypeParser/whileLoopParser';
import switchParser from './functionTypeParser/switchParser';
import resolveExpression from './simpleScript/expressionParser/resolveExpression';
import dbOpsParser from './functionTypeParser/dbOpsParser';
import networkRequestParser from './functionTypeParser/networkRequestParser';
import consoleLogParser from './functionTypeParser/consoleLogParser';
import secretKeyParser from './functionTypeParser/secretKeyParser';
import createScheduledTaskParser from './functionTypeParser/createScheduledTaskParser';
import setTimeoutParser from './functionTypeParser/setTimeoutParser';
import base64Parse from './functionTypeParser/base64Parse';
import parseCrypto from './functionTypeParser/parseCrypto';
import browserStorageParser from './functionTypeParser/browserStorageParser';
import objectAssignParser from './functionTypeParser/objectAssignParser';
import idParser from './functionTypeParser/idParser';
import windowLocationParser from './functionTypeParser/windowLocationParser';
import fileToBase64Parser from './functionTypeParser/fileToBase64Parser';
import xmlToJsonParser from './functionTypeParser/xmlToJsonParser';
import jsonToXmlParser from './functionTypeParser/jsonToXmlParser';
import stringToArrayBufferParser from './functionTypeParser/stringToArrayBufferParser';
import arrayBufferToBase64Parser from './functionTypeParser/arrayBufferToBase64Parser';
import jsonToCsvParser from './functionTypeParser/jsonToCsvParser';
import compressDecompressParser from './functionTypeParser/compressDecompressParser';
import objectSizeParser from './functionTypeParser/objectSizeParser';
import imageResizeParser from './functionTypeParser/imageResizeParser';
import getDomProperyParser from './functionTypeParser/getDomProperyParser';
import setDomPropertyParser from './functionTypeParser/setDomPropertyParser';
import eventBasicMethodParser from './functionTypeParser/eventBasicMethodParser';
import executeExternalFunctionParser from './functionTypeParser/executeExternalFunctionParser';
import navigationParser from './functionTypeParser/navigationParser';
import getEnvironmentParser from './functionTypeParser/getEnvironmentParser';
// import setCookieParser from './functionTypeParser/setCookieParser';
// import setResponseStatusParser from './functionTypeParser/setResponseStatusParser';
import sqlDbParser from './functionTypeParser/sqlDbParser';
import mongoDbParser from './functionTypeParser/mongoDbParser';
import redisParser from './functionTypeParser/redisParser';
import awsS3Parser from './functionTypeParser/awsS3Parser';
import remoteNetworkRequestParser from './functionTypeParser/remoteNetworkRequestParser';
import enrichSubscriberDataParser from './functionTypeParser/enrichSubscriberDataParser';
import getSubscriberCountParser from './functionTypeParser/getSubscriberCountParser';
import getSubscribersParser from './functionTypeParser/getSubscribersParser';
import generateBrowserPdfParser from './functionTypeParser/generateBrowserPdfParser';
import enrichProductDataParser from './functionTypeParser/enrichProductDataParser';
import productSearchParser from './functionTypeParser/productSearchParser';
import productCountParser from './functionTypeParser/productCountParser';
import enrichPaymentSubscriberDataParser from './functionTypeParser/enrichPaymentSubscriberDataParser';
import paymentSubscriberSearchParser from './functionTypeParser/paymentSubscriberSearchParser';
import getPaymentSubscriberCountParser from './functionTypeParser/getPaymentSubscriberCountParser';
import createOneTimePaymentLinkParser from './functionTypeParser/createOneTimePaymentLinkParser';
import createFixedSchedulePaymentLinkParser from './functionTypeParser/createFixedSchedulePaymentLinkParser';
import paymentLinkStatusUpdateParser from './functionTypeParser/paymentLinkStatusUpdateParser';
import deletePaymentLinkParser from './functionTypeParser/deletePaymentLinkParser';
import elementSetStateParser from './functionTypeParser/elementSetStateParser';
import updateParentAppDataParser from './functionTypeParser/updateParentAppDataParser';
import jwtSignParser from './functionTypeParser/jwtSignParser';
import jwtVerifyParser from './functionTypeParser/jwtVerifyParser';
import jwkToPemParser from './functionTypeParser/jwkToPemParser';
import manageSubscriptionUserRestrictionParser from './functionTypeParser/manageSubscriptionUserRestrictionParser';
import getSubscribedTenantParser from './functionTypeParser/getSubscribedTenantParser';
import getSubscribedTenantCountParser from './functionTypeParser/getSubscribedTenantCountParser';
import updateSubscriptionSubscriberDataParser from './functionTypeParser/updateSubscriptionSubscriberDataParser';
import deactivateScheduledTaskParser from './functionTypeParser/deactivateScheduledTaskParser';
import subscriptionOnDemandPaymentParser from './functionTypeParser/subscriptionOnDemandPaymentParser';
import paymentRefundParser from './functionTypeParser/paymentRefundParser';
import singlePaymentDistributionParser from './functionTypeParser/singlePaymentDistributionParser';
import batchPaymentDistributionParser from './functionTypeParser/batchPaymentDistributionParser';
import getCurrentPositionParser from './functionTypeParser/getCurrentPositionParser';
import watchPositionParser from './functionTypeParser/watchPositionParser';
import createInhouzSignDocumentParser from './functionTypeParser/createInhouzSignDocumentParser';
import sendInhouzSignDocumentParser from './functionTypeParser/sendInhouzSignDocumentParser';
import getInhouzSignDocumentParser from './functionTypeParser/getInhouzSignDocumentParser';
import getInhouzSignDocumentFileParser from './functionTypeParser/getInhouzSignDocumentFileParser';
import cancelInhouzSignDocumentParser from './functionTypeParser/cancelInhouzSignDocumentParser';
import getInhouzSignDocumentsParser from './functionTypeParser/getInhouzSignDocumentsParser';
import deleteInhouzSignDocumentParser from './functionTypeParser/deleteInhouzSignDocumentParser';
import generateInhouzSignUrlParser from './functionTypeParser/generateInhouzSignUrlParser';
import createInhouzProjectParser from './functionTypeParser/createInhouzProjectParser';
import getInhouzProjectParser from './functionTypeParser/getInhouzProjectParser';
import changeInhouzProjectOwnerParser from './functionTypeParser/changeInhouzProjectOwnerParser';
import changeInhouzProjectStatusParser from './functionTypeParser/changeInhouzProjectStatusParser';
import deleteInhouzProjectParser from './functionTypeParser/deleteInhouzProjectParser';
import getSystemUserApiKeyParser from './functionTypeParser/getSystemUserApiKeyParser';
import getInhouzProjectsParser from './functionTypeParser/getInhouzProjectsParser';
import downloadBase64FileParser from './functionTypeParser/downloadBase64FileParser';
import downloadCsvTextToFileParser from './functionTypeParser/downloadCsvTextToFileParser';
import indexedDBParser from './functionTypeParser/indexedDBParser';
import fileStorageUploadParser from './functionTypeParser/fileStorageUploadParser';
import fileStorageDeleteParser from './functionTypeParser/fileStorageDeleteParser';
import fileStorageGetFileParser from './functionTypeParser/fileStorageGetFileParser';
import generateServerPdfParser from './functionTypeParser/generateServerPdfParser';
import browserPushNotificationSubscriptionParser from './functionTypeParser/browserPushNotificationSubscriptionParser';
import singleBrowserPushNotificationParser from './functionTypeParser/singleBrowserPushNotificationParser';
import bulkBrowserPushNotificationParser from './functionTypeParser/bulkBrowserPushNotificationParser';
import getBrowserFingerprintParser from './functionTypeParser/getBrowserFingerprintParser';

async function recursiveFunctionStepParser (
    functionStepsData=[], variableMap={},
    parameterObject={}, functionMap={}, variableIdMap={},
    setStateFunction, runTests=false,
    responseAccumulator={}, stepCount=0,
    prevStepId=''
){
    let accumulator = responseAccumulator;
    try{
        let variableObject = stepCount === 0 ? 
        variableMap
        :
        (
            accumulator[`${prevStepId}.${stepCount - 1}`] &&
            accumulator[`${prevStepId}.${stepCount - 1}`]['updatedVariableObject']
        ) || {};

        if(
            accumulator[`${prevStepId}.${stepCount - 1}`] &&
            accumulator[`${prevStepId}.${stepCount - 1}`]['updatedVariableObject']
        ){
            delete accumulator[`${prevStepId}.${stepCount - 1}`]['updatedVariableObject'];
        }
        
        if(Array.isArray(functionStepsData)){
            for (let i = 0; i < functionStepsData.length; i++){
                let functionObject = functionStepsData[i];
                let {
                    _id=''
                } = functionObject;

                accumulator = await recursiveFunctionStepParser(
                    functionObject, variableObject, 
                    parameterObject, functionMap, variableIdMap, setStateFunction,
                    runTests, accumulator, i, functionStepsData[i - 1] && 
                    functionStepsData[i - 1]['_id']
                )
                
                if(
                    (accumulator[`${_id}.${i}`] && 
                    accumulator[`${_id}.${i}`]['hasReturnStatement'])
                    ||
                    (accumulator[`${_id}.${i}`] && 
                    accumulator[`${_id}.${i}`]['error'])
                    ||
                    (accumulator[`${_id}.${i}`] && 
                    accumulator[`${_id}.${i}`]['breakLoop'])
                    ||
                    (accumulator[`${_id}.${i}`] && 
                    accumulator[`${_id}.${i}`]['continueLoop'])
                ){
                    break;
                }
            }
        }else if(
            typeof functionStepsData === 'object' && 
            !Array.isArray(functionStepsData)
        ){
            let {
                functionType='',
                executionObject={}, _id=''
            } = functionStepsData;
            let {
                variableValue=[], mappingFunctions=[],
                returnValue=[], ifElseArray=[], data={}, isVariable=false,
                varId='', selfId='', variableType=''
            } = executionObject;
            let identifier = `${_id}.${stepCount}`;
            if(functionType === 'setVariable'){
                let field = await resolveExpression(
                    {
                        ...executionObject,
                        expressionMethods : [],
                        mappingFunctions : []
                    },
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                )
                let scriptValue = await simpleScriptParser(
                    variableValue,
                    field.variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                );
                
                if(mappingFunctions.length){
                    variableObject = await setMappedVariable(
                        field.value,
                        scriptValue.value,
                        mappingFunctions,
                        scriptValue.variableObject,
                        parameterObject,
                        functionMap,
                        variableIdMap
                    );
                    variableObject[selfId] = scriptValue.value;
                }else{
                    if(isVariable){
                        let varText = variableIdMap[variableType][varId];
                        variableObject[selfId] = scriptValue.value;
                        variableObject[varText] = scriptValue.value;
                    }else{
                        variableObject[field.value] = scriptValue.value;
                    }
                }
                
                accumulator[identifier] = {
                    updatedVariableObject : variableObject,
                    hasReturnStatement : false,
                    hasError : false,
                    returnStatement : null
                }
            }else if(functionType === 'returnStatement'){
                let scriptValue = await simpleScriptParser(
                    returnValue,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                );
                accumulator[identifier] = {
                    hasReturnStatement : true,
                    returnStatement : scriptValue.value,
                    updatedVariableObject : scriptValue.variableObject,
                    hasError : false
                }
            }else if(functionType === 'ifElse'){
                let parsedIfElse = await ifElseParser(
                    ifElseArray,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                )

                accumulator[identifier] = parsedIfElse;
            }else if(functionType === 'forLoop'){
                let parsedForLoop = await forLoopParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                )

                accumulator[identifier] = parsedForLoop;
            }else if(functionType === 'continue'){
                accumulator[identifier] = {
                    continueLoop : true,
                    updatedVariableObject : variableObject
                }
            }else if(functionType === 'break'){
                accumulator[identifier] = {
                    breakLoop : true,
                    updatedVariableObject : variableObject
                }
            }else if(functionType === 'whileLoop'){
                let parsedWhileLoop = await whileLoopParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );

                accumulator[identifier] = parsedWhileLoop;
            }else if(functionType === 'switch'){
                let parsedSwitchResponse = await switchParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSwitchResponse;
            }else if(functionType === 'dbOps'){
                let parsedDbOpsResponse = await dbOpsParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDbOpsResponse;
            }else if(functionType === 'networkRequest'){
                let parsedNRResponse = await networkRequestParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedNRResponse;
            }else if(functionType === 'remoteNetworkRequest'){
                let parsedRNRResponse = await remoteNetworkRequestParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedRNRResponse;
            }else if(functionType === 'consoleLog'){
                let parsedConsoleLog = await resolveExpression(
                    data,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                );
                consoleLogParser(parsedConsoleLog.value)

                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'setStateFunction'){
                if(setStateFunction){
                    let parsedAppData = await resolveExpression(
                        data,
                        variableObject,
                        parameterObject,
                        functionMap,
                        variableIdMap,
                    );
                    let newState = parsedAppData.value;
                    if(
                        typeof newState === 'object' && 
                        !Array.isArray(newState)
                    ){
                        setStateFunction(newState);
                    }
                }

                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'setAppLanguage'){
                if(setStateFunction){
                    let parsedLanguage = await resolveExpression(
                        data,
                        variableObject,
                        parameterObject,
                        functionMap,
                        variableIdMap,
                    );
                    if(typeof parsedLanguage.value === 'string'){
                        setStateFunction({lang : parsedLanguage.value}, true)
                    }
                }

                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'elementSetStateFunction'){
                let parsedElementState = await elementSetStateParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedElementState;
            }else if(functionType === 'updateParentAppData'){
                let parsedParentState = await updateParentAppDataParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedParentState;
            }else if(functionType === 'secretKey'){
                let parsedSecretKey = await secretKeyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSecretKey;
            }else if(functionType === 'createScheduledTask'){
                let parsedCreateTask = await createScheduledTaskParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCreateTask;
            }else if(functionType === 'setTimeout'){
                let parsedSetTimeout = await setTimeoutParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSetTimeout;
            }else if(functionType === 'base64Decode'){
                let decodedBase64 = await base64Parse(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = decodedBase64;
            }else if(functionType === 'base64Encode'){
                let encodedBase64 = await base64Parse(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = encodedBase64;
            }else if(functionType === 'encrypt'){
                let parsedEncryption = await parseCrypto(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEncryption;
            }else if(functionType === 'decrypt'){
                let parsedDecryption = await parseCrypto(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDecryption;
            }else if([
                'setLocalStorage', 'getLocalStorage', 
                'setSessionStorage', 'getSessionStorage',
                'removeSessionStorage', 'removeLocalStorage'
            ].includes(functionType)){
                let parsedBrowserStorage = await browserStorageParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedBrowserStorage;
            }else if(functionType === 'objectAssign'){
                let parsedMerge = await objectAssignParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedMerge;
            }else if(['guid', 'shortid'].includes(functionType)){
                let parsedId = await idParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedId;
            }else if(
                [
                    'windowPathname', 'windowHref',
                    'windowOrigin', 'windowHostname'
                ].includes(functionType)
            ){
                let parsedWindow = await windowLocationParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedWindow;
            }else if(functionType === 'fileToBase64'){
                let parsedFileToBase64 = await fileToBase64Parser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedFileToBase64;
            }else if(functionType === 'xmlToJson'){
                let parsedXmlToJson = await xmlToJsonParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedXmlToJson;
            }else if(functionType === 'jsonToXml'){
                let parsedJsonToXml = await jsonToXmlParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJsonToXml;
            }else if(functionType === 'stringToArrayBuffer'){
                let parsedStringToArrayBuffer = await stringToArrayBufferParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedStringToArrayBuffer;
            }else if(functionType === 'arrayBufferToBase64'){
                let parsedArrayBufferToBase64 = await arrayBufferToBase64Parser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedArrayBufferToBase64;
            }else if(['jsonToCsv', 'csvToJson'].includes(functionType)){
                let parsedCsvJsonConverter = await jsonToCsvParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCsvJsonConverter;
            }else if(['compressString', 'decompressString'].includes(functionType)){
                let parsedCompression = await compressDecompressParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCompression;
            }else if(functionType === 'objectSize'){
                let parsedObjectSize = await objectSizeParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedObjectSize;
            }else if(functionType === 'resizeImage'){
                let parsedImageResizer = await imageResizeParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedImageResizer;
            }else if(functionType === 'getDomProperty'){
                let parsedDomProperty = await getDomProperyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDomProperty;
            }else if(functionType === 'getDomMethod'){
                let parsedDomMethod = await getDomProperyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDomMethod;
            }else if(functionType === 'setDomProperty'){
                let parsedSetProp = await setDomPropertyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSetProp;
            }else if(
                [
                    'eventPreventDefault',
                    'eventStopPropagation'
                ].includes(functionType)
            ){
                let parsedEventMethod = await eventBasicMethodParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEventMethod;
            }else if(functionType === 'executeExternalFunction'){
                let parsedExternalFunction = await executeExternalFunctionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedExternalFunction;
            }else if(functionType === 'navigation'){
                let parsedNavigation = await navigationParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedNavigation;
            }else if(functionType === 'getEnvironment'){
                let parsedEnvironment = await getEnvironmentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEnvironment;
            }else if(
                [
                    'mysql', 'mssql', 'postgressql', 'oraclesql'
                ].includes(functionType)
            ){
                let parsedSql = await sqlDbParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSql;
            }else if(functionType === 'mongodb'){
                let parsedMongo = await mongoDbParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedMongo;
            }else if(functionType === 'redis'){
                let parsedRedis = await redisParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedRedis;
            }else if(functionType === 's3'){
                let parsedS3 = await awsS3Parser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedS3;
            }else if(functionType === 'enrichSubscriberData'){
                let parsedEnrichedSubscriberData = await enrichSubscriberDataParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEnrichedSubscriberData;
            }else if(functionType === 'getSubscriberCount'){
                let parsedSubscriberCount = await getSubscriberCountParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSubscriberCount;
            }else if(functionType === 'getSubscribers'){
                let parsedSubscribers = await getSubscribersParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSubscribers;
            }else if(
                functionType === 'generateBrowserPdf' && 
                typeof window !== 'undefined'
            ){
                let parsedGeneratePdf = await generateBrowserPdfParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedGeneratePdf;
            }else if(functionType === 'enrichProductData'){
                let enrichedProducts = await enrichProductDataParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = enrichedProducts;
            }else if(functionType === 'productSearch'){
                let parsedProductSearch = await productSearchParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedProductSearch;
            }else if(functionType === 'getProductCount'){
                let parsedProductCount = await productCountParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedProductCount;
            }else if(functionType === 'enrichPaymentSubscriberData'){
                let parsedEnrichedSubscriberData = await enrichPaymentSubscriberDataParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEnrichedSubscriberData;
            }else if(functionType === 'paymentSubscriberSearch'){
                let parsedPaymentSubscriberSearch = await paymentSubscriberSearchParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedPaymentSubscriberSearch;
            }else if(functionType === 'getPaymentSubscriberCount'){
                let parsedPaymentSubscriberCount = await getPaymentSubscriberCountParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedPaymentSubscriberCount;
            }else if(functionType === 'createOneTimePaymentLink'){
                let parsedOneTimePaymentLink = await createOneTimePaymentLinkParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedOneTimePaymentLink;
            }else if(functionType === 'createFixedSchedulePaymentLink'){
                let parsedFixedSchedulePaymentLink = await createFixedSchedulePaymentLinkParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedFixedSchedulePaymentLink;
            }else if(
                [
                    'activatePaymentLink', 
                    'deactivatePaymentLink'
                ].includes(functionType)
            ){
                let parsedPaymentLinkStatus = await paymentLinkStatusUpdateParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedPaymentLinkStatus;
            }else if(functionType === 'deletePaymentLink'){
                let parsedDeletePaymentLink = await deletePaymentLinkParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDeletePaymentLink;
            }else if(functionType === 'jwtSign'){
                let parsedJwtSign = await jwtSignParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJwtSign;
            }else if(functionType === 'jwtVerify'){
                let parsedJwtVerify = await jwtVerifyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJwtVerify;
            }else if(functionType === 'jwkToPem'){
                let parsedJwkToPem = await jwkToPemParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJwkToPem;
            }else if(
                [
                    'addToSubscriptionUserBlockList',
                    'removeFromSubscriptionUserBlockList',
                    'addToSubscriptionUserAllowList',
                    'removeFromSubscriptionUserAllowList'
                ].includes(functionType)
            ){
                let manageUserRestrictionParser = await manageSubscriptionUserRestrictionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = manageUserRestrictionParser;
            }else if(functionType === 'getSubscriptionServiceTenants'){
                let parsedSubscriptionServiceTenants = await getSubscribedTenantParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSubscriptionServiceTenants;
            }else if(functionType === 'getSubscriptionServiceTenantCount'){
                let parsedSubscriptionServiceTenantCount = await getSubscribedTenantCountParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSubscriptionServiceTenantCount;
            }else if(functionType === 'updateSubscriptionServiceUserData'){
                let parsedSubscriptionSubscriberDataUpdate = await updateSubscriptionSubscriberDataParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSubscriptionSubscriberDataUpdate;
            }else if(functionType === 'deactivateScheduledTask'){
                let parsedDeactivatedScheduledTask = await deactivateScheduledTaskParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDeactivatedScheduledTask;
            }else if(functionType === 'triggerSubscriptionOnDemandPayment'){
                let parsedOnDemandPayment = await subscriptionOnDemandPaymentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedOnDemandPayment;
            }else if(functionType === 'paymentRefund'){
                let parsedPaymentRefund = await paymentRefundParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedPaymentRefund;
            }else if(functionType === 'singlePaymentDistribution'){
                let parsedSinglePaymentDistribution = await singlePaymentDistributionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSinglePaymentDistribution;
            }else if(functionType === 'batchPaymentDistribution'){
                let parsedBatchPaymentDistribution = await batchPaymentDistributionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedBatchPaymentDistribution;
            }else if(functionType === 'geolocation.getCurrentPosition'){
                let parsedCurrentPosition = await getCurrentPositionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCurrentPosition;
            }else if(functionType === 'geolocation.watchPosition'){
                let parsedWatchPosition = await watchPositionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedWatchPosition;
            }else if(
                [
                    'setResponseHeader', 'setResponseStatus',
                    'setResponseCookies', 'apiReturnFile'
                ].includes(functionType)
            ){
                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'createInhouzSignDocument'){
                let parsedCreateInhouzSignDocument = await createInhouzSignDocumentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCreateInhouzSignDocument;
            }else if(functionType === 'sendInhouzSignDocument'){
                let parsedSendInhouzSignDocument = await sendInhouzSignDocumentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSendInhouzSignDocument;
            }else if(functionType === 'getInhouzSignDocument'){
                let parsedGetInhouzSignDocument = await getInhouzSignDocumentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedGetInhouzSignDocument;
            }else if(functionType === 'getInhouzSignDocumentFile'){
                let parsedGetInhouzSignDocumentFile = await getInhouzSignDocumentFileParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedGetInhouzSignDocumentFile;
            }else if(functionType === 'cancelInhouzSignDocument'){
                let parsedCancelInhouzSignDocument = await cancelInhouzSignDocumentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCancelInhouzSignDocument;
            }else if(functionType === 'getInhouzSignDocuments'){
                let parsedGetInhouzSignDocuments = await getInhouzSignDocumentsParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedGetInhouzSignDocuments;
            }else if(functionType === 'deleteInhouzSignDocument'){
                let parsedDeleteInhouzSignDocuments = await deleteInhouzSignDocumentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDeleteInhouzSignDocuments;
            }else if(functionType === 'generateInhouzSignUrl'){
                let parsedGenerateInhouzSignUrl = await generateInhouzSignUrlParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedGenerateInhouzSignUrl;
            }else if(functionType === 'createAnInhouzProject'){
                let parsedCreateInhouzProject = await createInhouzProjectParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCreateInhouzProject;
            }else if(functionType === 'getInhouzProject'){
                let parsedGetInhouzProject = await getInhouzProjectParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedGetInhouzProject;
            }else if(
                [
                    'activateInhouzProject',
                    'deactivateInhouzProject'
                ].includes(functionType)
            ){
                let parserdInhouzProjectState = await getInhouzProjectParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parserdInhouzProjectState;
            }else if(functionType === 'changeInhouzProjectOwner'){
                let parsedChangeInhouzProjectOwner = await changeInhouzProjectOwnerParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedChangeInhouzProjectOwner;
            }else if(functionType === 'changeInhouzProjectStatus'){
                let parsedChangeInhouzProjectStatus = await changeInhouzProjectStatusParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedChangeInhouzProjectStatus;
            }else if(functionType === 'deleteInhouzProject'){
                let parsedDeleteInhouzProject = await deleteInhouzProjectParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDeleteInhouzProject;
            }else if(functionType === 'getInhouzProjects'){
                let parsedGetInhouzProjects = await getInhouzProjectsParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedGetInhouzProjects;
            }else if(functionType === 'getSystemUserApiKey'){
                let parsedSystemUserApiKey = await getSystemUserApiKeyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSystemUserApiKey;
            }else if(functionType === 'downloadBase64File'){
                let parsedDownloadBase64File = await downloadBase64FileParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDownloadBase64File;
            }else if(functionType === 'downloadCsvTextToFile'){
                let parsedDownloadCsvTextToFile = await downloadCsvTextToFileParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDownloadCsvTextToFile;
            }else if(
                [
                    'indexedDbSet', 'indexedDbGet',
                    'indexedDbSetMany', 'indexedDbGetMany',
                    'indexedDbDelete', 'indexedDbDeleteMany',
                    'indexedDbClear', 'indexedDbGetKeys'
                ].includes(functionType)
            ){
                let indexedDbParser = await indexedDBParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = indexedDbParser;
            }else if(functionType === 'fileStorageUpload'){
                let parsedFileStorageUpload = await fileStorageUploadParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedFileStorageUpload;
            }else if(functionType === 'fileStorageDelete'){
                let parsedFileStorageDelete = await fileStorageDeleteParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedFileStorageDelete;
            }else if(
                [
                    'fileStorageGetFile', 'fileStorageGetSignedUrl'
                ].includes(functionType)
            ){
                let parsedFileStorageGetFile = await fileStorageGetFileParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedFileStorageGetFile;
            }else if(functionType === 'generateServerPdf'){
                let parsedFileStorageUpload = await generateServerPdfParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedFileStorageUpload;
            }else if(functionType === 'browserPushNotificationSubscription'){
                let parsedPushNotificationSubscription = await browserPushNotificationSubscriptionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedPushNotificationSubscription;
            }else if(functionType === 'singleBrowserPushNotification'){
                let parsedSingleBrowserPushNotification = await singleBrowserPushNotificationParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSingleBrowserPushNotification;
            }else if(functionType === 'bulkBrowserPushNotification'){
                let parsedBulkBrowserPushNotification = await bulkBrowserPushNotificationParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedBulkBrowserPushNotification;
            }else if(functionType === 'getBrowserFingerprintId'){
                let parsedBrowserFingerprintId = await getBrowserFingerprintParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedBrowserFingerprintId;
            }

            executionObject = null;
            functionType = null;
            variableValue = null;
            mappingFunctions = null;
            returnValue = null;
            ifElseArray = null;
            data = null;
        }

        functionStepsData = null;
        variableMap = null;
        parameterObject = null;
        functionMap = null;
        variableIdMap = null;
        
        runTests = null
        responseAccumulator = null;
        stepCount = null;
        prevStepId = null;
        variableObject = null;
        return accumulator;
    }catch(e){
        console.log('/utils/functionParser/recursiveFunctionStepParser catch block error', e);
        let id = functionStepsData['_id'];
        let identifier = `${id}.${stepCount}`;
        functionStepsData = null;
        variableMap = null;
        parameterObject = null;
        functionMap = null;
        variableIdMap = null;
        
        runTests = null
        responseAccumulator = null;
        stepCount = null;
        prevStepId = null;
        accumulator[identifier] = {
            errorPayload : e.stack,
            hasError : false,
            hasReturnStatement : false,
            returnStatement : null,
            updatedVariableObject : variableMap
        }
        return accumulator;
    }
}

export default recursiveFunctionStepParser;