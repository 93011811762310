import React from 'react';

const loadComponent = (importFunc) => {
  return React.lazy(async () => {
    let elementComponent;
    try {
        elementComponent = await importFunc();
        return elementComponent;
    } catch (error) {
        console.log('/elementLazyLoadMap loadComponent catch error', error);
        elementComponent = await importFunc();
        return elementComponent;
    }
  });
};

export default {
    CodeEditorViewer: () => loadComponent(() => import('../../elementUi/elements/codeEditorViewer')),
    ChartViewer: () => loadComponent(() => import('../../elementUi/elements/chartViewer')),
    RateViewer: () => loadComponent(() => import('../../elementUi/elements/rateViewer')),
    SwitchBuilderViewer: () => loadComponent(() => import('../../elementUi/elements/switchBuilderViewer')),
    WysiwygViewer: () => loadComponent(() => import('../../elementUi/elements/wysiwygViewer')),
    StripePaymentElementViewer: () => loadComponent(() => import('../../elementUi/elements/stripePaymentElementViewer')),
    PdfViewer: () => loadComponent(() => import('../../elementUi/elements/pdfViewer')),
    DivViewer: () => loadComponent(() => import('../../elementUi/elements/divViewer')),
    InputViewer: () => loadComponent(() => import('../../elementUi/elements/inputViewer')),
    RadioCheckboxViewer: () => loadComponent(() => import('../../elementUi/elements/radioCheckboxViewer')),
    SelectViewer: () => loadComponent(() => import('../../elementUi/elements/SelectViewer')),
    FileUploadViewer: () => loadComponent(() => import('../../elementUi/elements/fileUploadViewer')),
    SignatureViewer: () => loadComponent(() => import('../../elementUi/elements/signatureViewer')),
    ImageViewer: () => loadComponent(() => import('../../elementUi/elements/imageViewer')),
    EmbedViewer: () => loadComponent(() => import('../../elementUi/elements/embedViewer')),
    IconViewer: () => loadComponent(() => import('../../elementUi/elements/iconViewer')),
    RichTextEditorViewer: () => loadComponent(() => import('../../elementUi/elements/richTextEditorViewer')),
    IframeViewer: () => loadComponent(() => import('../../elementUi/elements/iFrameViewer')),
    ProgressBarViewer: () => loadComponent(() => import('../../elementUi/elements/progressBarViewer')),
    TimerViewer: () => loadComponent(() => import('../../elementUi/elements/timerViewer')),
    MediaPlayerViewer: () => loadComponent(() => import('../../elementUi/elements/mediaPlayerViewer')),
    TextViewer: () => loadComponent(() => import('../../elementUi/elements/textViewer')),
    ParagraphViewer: () => loadComponent(() => import('../../elementUi/elements/paragraphViewer')),
    HeadingViewer: () => loadComponent(() => import('../../elementUi/elements/headingViewer')),
    LinkViewer: () => loadComponent(() => import('../../elementUi/elements/linkViewer')),
    FormViewer: () => loadComponent(() => import('../../elementUi/elements/formViewer')),
    ButtonViewer: () => loadComponent(() => import('../../elementUi/elements/buttonViewer')),
    ListItemViewer: () => loadComponent(() => import('../../elementUi/elements/listItemViewer')),
    ListWrapperViewer: () => loadComponent(() => import('../../elementUi/elements/listWrapperViewer')),
    RecaptchaViewer: () => loadComponent(() => import('../../elementUi/elements/recaptchaViewer')),
    CanvasViewer: () => loadComponent(() => import('../../elementUi/elements/canvasViewer')),
    SlideNavViewer: () => loadComponent(() => import('../../elementUi/elements/slideNavViewer')),
    SliderViewer: () => loadComponent(() => import('../../elementUi/elements/sliderViewer')),
    SlideSelectorViewer: () => loadComponent(() => import('../../elementUi/elements/slideSelectorViewer')),
    TabControlViewer: () => loadComponent(() => import('../../elementUi/elements/tabControlViewer')),
    TabContentWrapperViewer: () => loadComponent(() => import('../../elementUi/elements/tabContentWrapperViewer')),
    TabWrapperViewer: () => loadComponent(() => import('../../elementUi/elements/tabWrapperViewer')),
    ModalViewer: () => loadComponent(() => import('../../elementUi/elements/modalViewer')),
    CalendarViewer: () => loadComponent(() => import('../../elementUi/elements/calendarViewer')),
    MapboxViewer: () => loadComponent(() => import('../../elementUi/elements/mapboxViewer')),
    TableViewer: () => loadComponent(() => import('../../elementUi/elements/tableViewer')),
    TableRowViewer: () => loadComponent(() => import('../../elementUi/elements/tableRowViewer')),
    TableDataRowWrapperViewer: () => loadComponent(() => import('../../elementUi/elements/tableDataRowWrapperViewer')),
    TableCellViewer: () => loadComponent(() => import('../../elementUi/elements/tableCellViewer')),
    TableHeaderCellViewer: () => loadComponent(() => import('../../elementUi/elements/tableHeaderCellViewer')),
    LinkBlockViewer: () => loadComponent(() => import('../../elementUi/elements/linkBlockViewer')),
    RichTextViewer: () => loadComponent(() => import('../../elementUi/elements/richTextViewer')),
    ControlledButtonViewer: () => loadComponent(() => import('../../elementUi/elements/controlledButtonViewer')),
    PaginationWrapperViewer: () => loadComponent(() => import('../../elementUi/elements/paginationWrapperViewer')),
    DisplayLimitSelectorViewer: () => loadComponent(() => import('../../elementUi/elements/displayLimitSelectorViewer')),
    ListPageCountTextViewer: () => loadComponent(() => import('../../elementUi/elements/listPageCountTextViewer')),
    ListIndexInputViewer: () => loadComponent(() => import('../../elementUi/elements/listIndexInputViewer')),
    ListDataRowWrapperViewer: () => loadComponent(() => import('../../elementUi/elements/listDataRowWrapperViewer')),
    CsvToJsonUploaderViewer: () => loadComponent(() => import('../../elementUi/elements/csvToJsonUploaderViewer')),
    WebComponentViewer: () => loadComponent(() => import('../../elementUi/elements/webComponentViewer')),
    AutoCompleteViewer: () => loadComponent(() => import('../../elementUi/elements/autoCompleteViewer')),
};