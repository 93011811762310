import React, {Component} from "react";
import {connect} from 'react-redux';
import { Route, Switch } from "react-router-dom";
import { isEqual } from "lodash";
import {
  subscriptionGetUser, setSubscriptionAuthReducer,
  setUiComponentCache, triggerComponentLoad
} from '../actions';
import AppViewer from '../components/customComponents/appViewer/appViewer';
// /* eslint-disable-next-line */
// import calculateListKeyTrackerWorker from 'workerize-loader?inline!../worker/calculateListKeyTracker.worker.js';
// /* eslint-disable-next-line */
// import attributeWorker from 'workerize-loader?inline!../worker/attributeFunction.worker.js';
// /* eslint-disable-next-line */
// import getWebsiteResourcesWorker from 'workerize-loader?inline!../worker/getWebsiteResources.worker.js';
// /* eslint-disable-next-line */
// import getWebComponentWorker from 'workerize-loader?inline!../worker/getWebComponent.worker.js';
/* eslint-disable-next-line */
import appDataChangeWorker from 'workerize-loader?inline!../worker/appDataChangeLifecycle.worker.js';
import elementComponentNameMap from "../components/customComponents/appViewer/utils/uiRenderUtils/elementComponentNameMap";
// /* eslint-disable-next-line */
// import bulkAttributeWorker from 'workerize-loader?inline!../worker/bulkAttributeParser.worker.js';

class App extends Component{
  state = {
    workerInitTracker : 0
  }
  calculateListKeyTrackerWorkerInstance;
  workerInstance;
  websiteResourceWorkerInstance;
  webComponentWorkerInstance;
  appDataChangeWorkerInstance;
  bulkAttributeWorkerInstance;

  componentDidMount(){
    // this.workerInstance = attributeWorker();
    // this.bulkAttributeWorkerInstance = bulkAttributeWorker();
    // this.websiteResourceWorkerInstance = getWebsiteResourcesWorker();
    // this.webComponentWorkerInstance = getWebComponentWorker();
    this.preLoadComponents();
    this.appDataChangeWorkerInstance = appDataChangeWorker();
    // this.calculateListKeyTrackerWorkerInstance = calculateListKeyTrackerWorker();
    this.setState({
      workerInitTracker : Math.random()
    });
    const {subscriptionGetUser} = this.props;
    if(subscriptionGetUser){
      subscriptionGetUser();
    }
  }

  componentDidUpdate(prevProps){
    const {
      logoutIsLoading, logoutError,
      logoutMode
    } = this.props;
    if(
      logoutMode && 
      !logoutIsLoading && 
      !logoutError && 
      !isEqual(logoutIsLoading, prevProps.logoutIsLoading)
    ){
      this.handlePostLogout();
    }
  }

  componentWillUnmount = () => {
    this.removeWebworkers();
  }

  preLoadComponents = () => {
    const {
      loadedComponents={}, triggerComponentLoad,
      setUiComponentCache
    } = this.props;
    let tracker = {};
    for (let k in elementComponentNameMap){
      let elementName = elementComponentNameMap[k];
      if(
        !tracker[elementName] && 
        !loadedComponents[elementName]
      ){
        triggerComponentLoad(k);
        setUiComponentCache(k);
        tracker[elementName] = true;
      }
    }
  }

  removeWebworkers = () => {
    if(
        this.workerInstance && 
        this.workerInstance.terminate
    ){
        this.workerInstance.terminate();
    }
    if(
        this.websiteResourceWorkerInstance && 
        this.websiteResourceWorkerInstance.terminate
    ){
        this.websiteResourceWorkerInstance.terminate();
    }
    if(
        this.webComponentWorkerInstance && 
        this.webComponentWorkerInstance.terminate
    ){
        this.webComponentWorkerInstance.terminate();
    }
    if(
      this.appDataChangeWorkerInstance && 
      this.appDataChangeWorkerInstance.terminate
    ){
        this.appDataChangeWorkerInstance.terminate();
    }
    // if(
    //     this.calculateListKeyTrackerWorkerInstance && 
    //     this.calculateListKeyTrackerWorkerInstance.terminate
    // ){
    //     this.calculateListKeyTrackerWorkerInstance.terminate();
    // }
    if(
      this.bulkAttributeWorkerInstance && 
      this.bulkAttributeWorkerInstance.terminate
    ){
      this.bulkAttributeWorkerInstance.terminate();
    }
  }

  handlePostLogout = () => {
    const {setSubscriptionAuthReducer} = this.props;
    if(setSubscriptionAuthReducer){
      setSubscriptionAuthReducer({
        logoutMode : false,
        subscriberData : {},
        subcriberDataLoading : false,
        subscriberDataError : false,
        subscriberDataErrorMessage : '',
      });
    }
  }

  getPrimaryWorkerInstance = () => {
    return this.workerInstance;
  }

  getListUpdateTrackerWorkerInstance = () => {
    return this.calculateListKeyTrackerWorkerInstance;
  }

  getWebsiteResourceWorkerInstance = () => {
    return this.websiteResourceWorkerInstance;
  }

  getWebComponentWorkerInstance = () => {
    return this.webComponentWorkerInstance;
  }

  getAppDataChangeWorkerInstance = () => {
    return this.appDataChangeWorkerInstance;
  }

  getBulkAttributeWorkerInstance = () => {
    return this.bulkAttributeWorkerInstance;
  }

  render(){
    return (
      <>
        <Switch>
          <Route 
            path="*" 
            // component={AppViewer} 
            render={(props) => <AppViewer 
                {...props} 
                calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                workerInstanceProp={this.getPrimaryWorkerInstance}
                websiteResourceWorkerInstanceProp={this.getWebsiteResourceWorkerInstance}
                webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                bulkAttributeWorkerInstanceProp={this.getBulkAttributeWorkerInstance}
                workerInitTracker={this.state.workerInitTracker}
              />
            }
          />
        </Switch>
      </>
    );
  }
};

function mapStateToProps(state){
  return {
      logoutIsLoading : state.SubscriptionAuth.logoutIsLoading,
      logoutError: state.SubscriptionAuth.logoutError,
      logoutMode: state.SubscriptionAuth.logoutMode,
      loadedComponents : state.UiComponent.loadedComponents,
  }
}

export default connect(mapStateToProps, {
  subscriptionGetUser, setSubscriptionAuthReducer,
  setUiComponentCache, triggerComponentLoad
})(App);

