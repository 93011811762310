import React, {Component, Suspense} from 'react';
import { connect } from 'react-redux';
import {
    setUiComponentCache, triggerComponentLoad
} from '../../../../actions';
// import DivViewer from './elements/divViewer';
// import InputViewer from './elements/inputViewer';
// import RadioCheckboxViewer from './elements/radioCheckboxViewer';
// import SwitchBuilderViewer from './elements/switchBuilderViewer';
// import SelectViewer from './elements/SelectViewer';
// import FileUploadViewer from './elements/fileUploadViewer';
// import SignatureViewer from './elements/signatureViewer';
// import ImageViewer from './elements/imageViewer';
// import CodeEditorViewer from './elements/codeEditorViewer';
// import EmbedViewer from './elements/embedViewer';
// import IconViewer from './elements/iconViewer';
// import RichTextEditorViewer from './elements/richTextEditorViewer';
// import WysiwygViewer from './elements/wysiwygViewer';
// import IframeViewer from './elements/iFrameViewer';
// import ProgressBarViewer from './elements/progressBarViewer';
// import RateViewer from './elements/rateViewer';
// import TimerViewer from './elements/timerViewer';
// import MediaPlayerViewer from './elements/mediaPlayerViewer';
// import TextViewer from './elements/textViewer';
// import ParagraphViewer from './elements/paragraphViewer';
// import HeadingViewer from './elements/headingViewer';
// import LinkViewer from './elements/linkViewer';
// import FormViewer from './elements/formViewer';
// import ButtonViewer from './elements/buttonViewer';
// import ListItemViewer from './elements/listItemViewer';
// import ListWrapperViewer from './elements/listWrapperViewer';
// import RecaptchaViewer from './elements/recaptchaViewer';
// import PdfViewer from './elements/pdfViewer';
// import CanvasViewer from './elements/canvasViewer';
// import SlideNavViewer from './elements/slideNavViewer';
// import SliderViewer from './elements/sliderViewer';
// import SlideSelectorViewer from './elements/slideSelectorViewer';
// import TabControlViewer from './elements/tabControlViewer';
// import TabContentWrapperViewer from './elements/tabContentWrapperViewer';
// import TabWrapperViewer from './elements/tabWrapperViewer';
// import ModalViewer from './elements/modalViewer';
// import CalendarViewer from './elements/calendarViewer';
// import MapboxViewer from './elements/mapboxViewer';
// import ChartViewer from './elements/chartViewer';
// import TableViewer from './elements/tableViewer';
// import TableRowViewer from './elements/tableRowViewer';
// import TableDataRowWrapperViewer from './elements/tableDataRowWrapperViewer';
// import TableCellViewer from './elements/tableCellViewer';
// import TableHeaderCellViewer from './elements/tableHeaderCellViewer';
// import LinkBlockViewer from './elements/linkBlockViewer';
// import RichTextViewer from './elements/richTextViewer';
// import ControlledButtonViewer from './elements/controlledButtonViewer';
import ErrorBoundary from '../../errorBoundary';
import elementComponentNameMap from '../utils/uiRenderUtils/elementComponentNameMap';
// import elementFilePath from '../utils/uiRenderUtils/elementFilePath';
// import PaginationWrapperViewer from './elements/paginationWrapperViewer';
// import DisplayLimitSelectorViewer from './elements/displayLimitSelectorViewer';
// import ListPageCountTextViewer from './elements/listPageCountTextViewer';
// import ListIndexInputViewer from './elements/listIndexInputViewer';
// import ListDataRowWrapperViewer from './elements/listDataRowWrapperViewer';
// import CsvToJsonUploaderViewer from './elements/csvToJsonUploaderViewer';
// import WebComponentViewer from './elements/webComponentViewer';
// import AutoCompleteViewer from './elements/autoCompleteViewer';
// import StripePaymentElementViewer from './elements/stripePaymentElementViewer';

class RecursiveViewerElementUi extends Component{

    // state = {
    //     CodeEditorViewer : null,
    //     ChartViewer : null,
    //     RateViewer : null,
    //     SwitchBuilderViewer : null,
    //     WysiwygViewer : null,
    //     StripePaymentElementViewer : null,
    //     PdfViewer : null,
    //     DivViewer : null,
    //     InputViewer : null,
    //     RadioCheckboxViewer : null,
    //     SelectViewer : null,
    //     FileUploadViewer : null,
    //     SignatureViewer : null,
    //     ImageViewer : null,
    //     EmbedViewer : null,
    //     IconViewer : null,
    //     RichTextEditorViewer : null,
    //     IframeViewer : null,
    //     ProgressBarViewer : null,
    //     TimerViewer : null,
    //     MediaPlayerViewer : null,
    //     TextViewer : null,
    //     ParagraphViewer : null,
    //     HeadingViewer : null,
    //     LinkViewer : null,
    //     FormViewer : null,
    //     ButtonViewer : null,
    //     ListItemViewer : null,
    //     ListWrapperViewer : null,
    //     RecaptchaViewer : null,
    //     CanvasViewer : null,
    //     SlideNavViewer : null,
    //     SliderViewer : null,
    //     SlideSelectorViewer : null,
    //     TabControlViewer : null,
    //     TabContentWrapperViewer : null,
    //     TabWrapperViewer : null,
    //     ModalViewer : null,
    //     CalendarViewer : null,
    //     MapboxViewer : null,
    //     TableViewer : null,
    //     TableRowViewer : null,
    //     TableDataRowWrapperViewer : null,
    //     TableCellViewer : null,
    //     TableHeaderCellViewer : null,
    //     LinkBlockViewer : null,
    //     RichTextViewer : null,
    //     ControlledButtonViewer : null,
    //     PaginationWrapperViewer : null,
    //     DisplayLimitSelectorViewer : null,
    //     ListPageCountTextViewer : null,
    //     ListIndexInputViewer : null,
    //     ListDataRowWrapperViewer : null,
    //     CsvToJsonUploaderViewer : null,
    //     WebComponentViewer : null,
    //     AutoCompleteViewer : null
    // }

    componentDidMount(){
        if(typeof window !== 'undefined'){
            let {
                element={}, 
                setUiComponentCache, loadedComponents={},
                triggerComponentLoad
            } = this.props;
            let {elementType=''} = element;
            if(
                elementType &&
                !loadedComponents[elementComponentNameMap[elementType]]
            ){
                triggerComponentLoad(elementType);
                setUiComponentCache(elementType);
            }
            element = null;
            setUiComponentCache = null;
            loadedComponents = null;
            elementType = null;

            // this.setState({
            //     CodeEditorViewer : elementType === 'codeEditor' ? React.lazy(() => import('./elements/codeEditorViewer')) : null,
            //     ChartViewer : elementType === 'chart' ? React.lazy(() => import('./elements/chartViewer')) : null,
            //     RateViewer : elementType === 'rate' ? React.lazy(() => import('./elements/rateViewer')) : null,
            //     SwitchBuilderViewer : elementType === 'switch' ? React.lazy(() => import('./elements/switchBuilderViewer')) : null,
            //     WysiwygViewer : elementType === 'wysiwyg' ? React.lazy(() => import('./elements/wysiwygViewer')) : null,
            //     StripePaymentElementViewer : elementType === 'stripePaymentElement' ? React.lazy(() => import('./elements/stripePaymentElementViewer')) : null,
            //     PdfViewer : elementType === 'pdfViewer' ? React.lazy(() => import('./elements/pdfViewer')) : null,
            //     DivViewer : [
            //         'div', 'container', 'section', 
            //         'navbar', 'slide', 'tab',
            //         'tabMenu', 'body'
            //     ].includes(elementType) ? React.lazy(() => import('./elements/divViewer')) : null,
            //     InputViewer : ['input', 'textarea'].includes(elementType) ? React.lazy(() => import('./elements/inputViewer')) : null,
            //     RadioCheckboxViewer : ['checkbox', 'radioButton'].includes(elementType) ? React.lazy(() => import('./elements/radioCheckboxViewer')) : null,
            //     SelectViewer : elementType === 'select' ? React.lazy(() => import('./elements/SelectViewer')) : null,
            //     FileUploadViewer : elementType === 'fileUpload' ? React.lazy(() => import('./elements/fileUploadViewer')) : null,
            //     SignatureViewer : elementType === 'signaturePad' ? React.lazy(() => import('./elements/signatureViewer')) : null,
            //     ImageViewer : elementType === 'image' ? React.lazy(() => import('./elements/imageViewer')) : null,
            //     EmbedViewer : elementType === 'embed' ? React.lazy(() => import('./elements/embedViewer')) : null,
            //     IconViewer : ['icon', 'listNav'].includes(elementType) ? React.lazy(() => import('./elements/iconViewer')) : null,
            //     RichTextEditorViewer : elementType === 'richTextEditor' ? React.lazy(() => import('./elements/richTextEditorViewer')) : null,
            //     IframeViewer : elementType === 'iFrame' ? React.lazy(() => import('./elements/iFrameViewer')) : null,
            //     ProgressBarViewer : elementType === 'progressBar' ? React.lazy(() => import('./elements/progressBarViewer')) : null,
            //     TimerViewer : elementType === 'timer' ? React.lazy(() => import('./elements/timerViewer')) : null,
            //     MediaPlayerViewer : elementType === 'mediaPlayer' ? React.lazy(() => import('./elements/mediaPlayerViewer')) : null,
            //     TextViewer : ['textBlock', 'blockQuote'].includes(elementType) ? React.lazy(() => import('./elements/textViewer')) : null,
            //     ParagraphViewer : elementType === 'paragraph' ? React.lazy(() => import('./elements/paragraphViewer')) : null,
            //     HeadingViewer : elementType === 'heading' ? React.lazy(() => import('./elements/headingViewer')) : null,
            //     LinkViewer : elementType === 'link' ? React.lazy(() => import('./elements/linkViewer')) : null,
            //     FormViewer : elementType === 'form' ? React.lazy(() => import('./elements/formViewer')) : null,
            //     ButtonViewer : elementType === 'button' ? React.lazy(() => import('./elements/buttonViewer')) : null,
            //     ListItemViewer : elementType === 'listItem' ? React.lazy(() => import('./elements/listItemViewer')) : null,
            //     ListWrapperViewer : elementType === 'list' ? React.lazy(() => import('./elements/listWrapperViewer')) : null,
            //     RecaptchaViewer : elementType === 'recaptcha' ? React.lazy(() => import('./elements/recaptchaViewer')) : null,
            //     CanvasViewer : elementType === 'canvas' ? React.lazy(() => import('./elements/canvasViewer')) : null,
            //     SlideNavViewer : elementType === 'slideNav' ? React.lazy(() => import('./elements/slideNavViewer')) : null,
            //     SliderViewer : elementType === 'slider' ? React.lazy(() => import('./elements/sliderViewer')) : null,
            //     SlideSelectorViewer : elementType === 'slideSelector' ? React.lazy(() => import('./elements/slideSelectorViewer')) : null,
            //     TabControlViewer : elementType === 'tabControl' ? React.lazy(() => import('./elements/tabControlViewer')) : null,
            //     TabContentWrapperViewer : elementType === 'tabContentWrapper' ? React.lazy(() => import('./elements/tabContentWrapperViewer')) : null,
            //     TabWrapperViewer : elementType === 'tabWrapper' ? React.lazy(() => import('./elements/tabWrapperViewer')) : null,
            //     ModalViewer : elementType === 'modal' ? React.lazy(() => import('./elements/modalViewer')) : null,
            //     CalendarViewer : elementType === 'calendar' ? React.lazy(() => import('./elements/calendarViewer')) : null,
            //     MapboxViewer : elementType === 'mapbox' ? React.lazy(() => import('./elements/mapboxViewer')) : null,
            //     TableViewer : elementType === 'table' ? React.lazy(() => import('./elements/tableViewer')) : null,
            //     TableRowViewer : [
            //         'tableHeaderRow', 'tableFilterRow', 
            //         'tableDataRow'
            //     ].includes(elementType) ? React.lazy(() => import('./elements/tableRowViewer')) : null,
            //     TableDataRowWrapperViewer : elementType === 'tableDataRowWrapper' ? React.lazy(() => import('./elements/tableDataRowWrapperViewer')) : null,
            //     TableCellViewer : [
            //         'columnFilter',
            //         'columnCell'
            //     ].includes(elementType) ? React.lazy(() => import('./elements/tableCellViewer')) : null,
            //     TableHeaderCellViewer : elementType === 'columnHeader' ? React.lazy(() => import('./elements/tableHeaderCellViewer')) : null,
            //     LinkBlockViewer : elementType === 'linkBlock' ? React.lazy(() => import('./elements/linkBlockViewer')) : null,
            //     RichTextViewer : elementType === 'richTextViewer' ? React.lazy(() => import('./elements/richTextViewer')) : null,
            //     ControlledButtonViewer : [
            //         'login_button', 'logout_button', 
            //         'profile_button'
            //     ].includes(elementType) ? React.lazy(() => import('./elements/controlledButtonViewer')) : null,
            //     PaginationWrapperViewer : elementType === 'paginationWrapper' ? React.lazy(() => import('./elements/paginationWrapperViewer')) : null,
            //     DisplayLimitSelectorViewer : elementType === 'displayLimitSelector' ? React.lazy(() => import('./elements/displayLimitSelectorViewer')) : null,
            //     ListPageCountTextViewer : elementType === 'listPageCountText' ? React.lazy(() => import('./elements/listPageCountTextViewer')) : null,
            //     ListIndexInputViewer : elementType === 'listIndexInput' ? React.lazy(() => import('./elements/listIndexInputViewer')) : null,
            //     ListDataRowWrapperViewer : elementType === 'listDataRowWrapper' ? React.lazy(() => import('./elements/listDataRowWrapperViewer')) : null,
            //     CsvToJsonUploaderViewer : elementType === 'csvToJsonUploader' ? React.lazy(() => import('./elements/csvToJsonUploaderViewer')) : null,
            //     WebComponentViewer : elementType === 'webComponent' ? React.lazy(() => import('./elements/webComponentViewer')) : null,
            //     AutoCompleteViewer : elementType === 'autoComplete' ? React.lazy(() => import('./elements/autoCompleteViewer')) : null,
            // })
        }
    }

    // componentWillUnmount(){
    //     this.setState({
    //         CodeEditorViewer : null,
    //         ChartViewer : null,
    //         RateViewer : null,
    //         SwitchBuilderViewer : null,
    //         WysiwygViewer : null,
    //         StripePaymentElementViewer : null,
    //         PdfViewer : null,
    //         DivViewer : null,
    //         InputViewer : null,
    //         RadioCheckboxViewer : null,
    //         SelectViewer : null,
    //         FileUploadViewer : null,
    //         SignatureViewer : null,
    //         ImageViewer : null,
    //         EmbedViewer : null,
    //         IconViewer : null,
    //         RichTextEditorViewer : null,
    //         IframeViewer : null,
    //         ProgressBarViewer : null,
    //         TimerViewer : null,
    //         MediaPlayerViewer : null,
    //         TextViewer : null,
    //         ParagraphViewer : null,
    //         HeadingViewer : null,
    //         LinkViewer : null,
    //         FormViewer : null,
    //         ButtonViewer : null,
    //         ListItemViewer : null,
    //         ListWrapperViewer : null,
    //         RecaptchaViewer : null,
    //         CanvasViewer : null,
    //         SlideNavViewer : null,
    //         SliderViewer : null,
    //         SlideSelectorViewer : null,
    //         TabControlViewer : null,
    //         TabContentWrapperViewer : null,
    //         TabWrapperViewer : null,
    //         ModalViewer : null,
    //         CalendarViewer : null,
    //         MapboxViewer : null,
    //         TableViewer : null,
    //         TableRowViewer : null,
    //         TableDataRowWrapperViewer : null,
    //         TableCellViewer : null,
    //         TableHeaderCellViewer : null,
    //         LinkBlockViewer : null,
    //         RichTextViewer : null,
    //         ControlledButtonViewer : null,
    //         PaginationWrapperViewer : null,
    //         DisplayLimitSelectorViewer : null,
    //         ListPageCountTextViewer : null,
    //         ListIndexInputViewer : null,
    //         ListDataRowWrapperViewer : null,
    //         CsvToJsonUploaderViewer : null,
    //         WebComponentViewer : null,
    //         AutoCompleteViewer : null
    //     });
    // }

    renderElementSwitch = () => {
        const {
            elementId='', parentElementId='', 
            //appMap={},
            parentDisplayFlex=false, 
            elementPosition=-1, inList=false, listItemIndex=-1,
            listItemData={}, pathToListItem='', currentSlidePositionField='',
            sliderIndex=-1, slideCount=0, showControls=false, 
            infiniteSlides=false, tabCountField='', activeTabPositionField='',
            columnHeaderElementIds=[], columnFilterElementIds=[],
            columnCellElementIds=[], rowType='', tableData=[], listData=[],
            columnWidthMap={}, appId='', setAppDataFunction,
            workerInstance, updateTableHeaderWidth, tableColumnBalanced=false,
            appEnvironment, historyPushFunction,
            tabWrapperElementId,
            paginationDisplayLimitOptions=[], pageIndex=0, paginationPageCount=0,
            paginationDisplayLimit=0, updatePaginationField,
            changePageIndex, updateTableSort, triggerScrollQuery,
            queryOnScroll, scrollDirection='', setElementAppData,
            setParentAppData, listItemBaseIndex=0, listKeyTracker={},
            rowDataBaseIndex=0, element={}, cachedUiComponents={},
            parentElementModeFunctions={}
        } = this.props;
        const {
            CodeEditorViewer, ChartViewer, RateViewer,
            SwitchBuilderViewer, StripePaymentElementViewer,
            WysiwygViewer, PdfViewer, DivViewer, InputViewer,
            RadioCheckboxViewer, SelectViewer, FileUploadViewer,
            SignatureViewer, ImageViewer, EmbedViewer, IconViewer,
            RichTextEditorViewer, IframeViewer, ProgressBarViewer,
            TimerViewer, MediaPlayerViewer, TextViewer, ParagraphViewer,
            HeadingViewer, LinkViewer, FormViewer, ButtonViewer,
            ListItemViewer, ListWrapperViewer, RecaptchaViewer, 
            CanvasViewer, SlideNavViewer, SliderViewer, SlideSelectorViewer,
            TabControlViewer, TabContentWrapperViewer, TabWrapperViewer,
            ModalViewer, CalendarViewer, MapboxViewer, TableViewer,
            TableRowViewer, TableDataRowWrapperViewer, TableCellViewer,
            TableHeaderCellViewer, LinkBlockViewer, RichTextViewer,
            ControlledButtonViewer, PaginationWrapperViewer, 
            DisplayLimitSelectorViewer, ListPageCountTextViewer, 
            ListIndexInputViewer, ListDataRowWrapperViewer, CsvToJsonUploaderViewer,
            WebComponentViewer, AutoCompleteViewer
        } = cachedUiComponents;
        // const appObj = appMap[appId] || {};
        // const {appElementsMap={}} = appObj;
        // let elementRef = appElementsMap[elementId] || {};
        // let {
        //     elementType=''
        // } = elementRef;
        let {
            elementType=''
        } = element;
        // if(
        //     element['elementRefId'] === 'textBlock135' && 
        //     (this.props.listItemIndex === 0) 
        //     // && 
        //     // nextProps.batchComputeCache[`${appId}_${this.props.elementId}_${this.props.listItemPathId}_${this.props.listItemIndex}`]
        // ){
        //     console.log('recursive render')
        // }

        return (
            <React.Fragment>
                {
                    [
                        'div', 'container', 'section', 
                        'navbar', 'slide', 'tab',
                        'tabMenu', 'body'
                    ].includes(elementType) && 
                    DivViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <DivViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                tabWrapperElementId={tabWrapperElementId}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'webComponent' && 
                    WebComponentViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <WebComponentViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                tabWrapperElementId={tabWrapperElementId}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'stripePaymentElement' && 
                    StripePaymentElementViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <StripePaymentElementViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                tabWrapperElementId={tabWrapperElementId}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'autoComplete' && 
                    AutoCompleteViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <AutoCompleteViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                tabWrapperElementId={tabWrapperElementId}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'button' && 
                    ButtonViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ButtonViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    [
                        'login_button', 'logout_button', 
                        'profile_button'
                    ].includes(elementType) && 
                    ControlledButtonViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ControlledButtonViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'calendar' && 
                    CalendarViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <CalendarViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'canvas' && 
                    CanvasViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <CanvasViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'chart' && 
                    ChartViewer && 
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ChartViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                        listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                    listItemPathId={this.props.listItemPathId}
                                getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'rate' && 
                    RateViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <RateViewer
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                        listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                    listItemPathId={this.props.listItemPathId}
                                getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'switch' && 
                    SwitchBuilderViewer && 
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <SwitchBuilderViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                        listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                    listItemPathId={this.props.listItemPathId}
                                getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'codeEditor' && 
                    (typeof window !== 'undefined') &&
                    CodeEditorViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <CodeEditorViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                        listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                    listItemPathId={this.props.listItemPathId}
                                getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'embed' && 
                    EmbedViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <EmbedViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'fileUpload' && 
                    FileUploadViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <FileUploadViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'form' && 
                    FormViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <FormViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'heading' && 
                    HeadingViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <HeadingViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    ['icon', 'listNav'].includes(elementType) && 
                    IconViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <IconViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                isListNav={elementType === 'listNav'}
                                changePageIndex={changePageIndex}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'iFrame' && 
                    IframeViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <IframeViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'image' &&
                    ImageViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ImageViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    ['input', 'textarea'].includes(elementType) && 
                    InputViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <InputViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'csvToJsonUploader' && 
                    CsvToJsonUploaderViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <CsvToJsonUploaderViewer
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    ['linkBlock'].includes(elementType) && 
                    LinkBlockViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <LinkBlockViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'link' && 
                    LinkViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <LinkViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'listItem'  && 
                    ListItemViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ListItemViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'listDataRowWrapper' && 
                    ListDataRowWrapperViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ListDataRowWrapperViewer
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                listKeyTracker={listKeyTracker}
                                pathToListItem={pathToListItem} 
                                listData={listData}
                                triggerScrollQuery={triggerScrollQuery}
                                queryOnScroll={queryOnScroll}
                                scrollDirection={scrollDirection}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'list' && 
                    ListWrapperViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ListWrapperViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'mapbox' && 
                    MapboxViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <MapboxViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'mediaPlayer' && 
                    MediaPlayerViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <MediaPlayerViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'modal' && 
                    ModalViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ModalViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'paragraph' && 
                    ParagraphViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ParagraphViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'pdfViewer' && 
                    PdfViewer && 
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <PdfViewer
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                        listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                    listItemPathId={this.props.listItemPathId}
                                getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'progressBar' && 
                    ProgressBarViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ProgressBarViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    ['checkbox', 'radioButton'].includes(elementType) &&
                    RadioCheckboxViewer && 
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <RadioCheckboxViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'recaptcha' && 
                    RecaptchaViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <RecaptchaViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'richTextEditor' && 
                    RichTextEditorViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <RichTextEditorViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {/* {
                    elementType === 'richTextEditor' && 
                    (typeof window !== 'undefined') &&
                    CodeEditorViewer &&
                    <Suspense fallback={<React.Fragment></React.Fragment>}>
                        <ErrorBoundary>
                            <RichTextEditorViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                        listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                    listItemPathId={this.props.listItemPathId}
                                getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </ErrorBoundary>
                    </Suspense>
                } */}
                {
                    elementType === 'wysiwyg' && 
                    (typeof window !== 'undefined') &&
                    WysiwygViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <WysiwygViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                        listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                    listItemPathId={this.props.listItemPathId}
                                getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'richTextViewer' && 
                    RichTextViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <RichTextViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'select' && 
                    SelectViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <SelectViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'signaturePad' && 
                    SignatureViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <SignatureViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'slideNav' && 
                    SlideNavViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <SlideNavViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                slideCount={slideCount}
                                sliderIndex={sliderIndex}
                                showControls={showControls}
                                infiniteSlides={infiniteSlides}
                                currentSlidePositionField={currentSlidePositionField}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'slider' && 
                    SliderViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <SliderViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'slideSelector' && 
                    SlideSelectorViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <SlideSelectorViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                slideCount={slideCount}
                                sliderIndex={sliderIndex}
                                showControls={showControls}
                                infiniteSlides={infiniteSlides}
                                currentSlidePositionField={currentSlidePositionField}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    ['tabContentWrapper'].includes(elementType) &&
                    TabContentWrapperViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TabContentWrapperViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                tabWrapperElementId={tabWrapperElementId}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    ['tabControl'].includes(elementType) && 
                    TabControlViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TabControlViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                tabWrapperElementId={tabWrapperElementId}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    [
                        'columnFilter',
                        'columnCell'
                    ].includes(elementType) &&
                    TableCellViewer && 
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TableCellViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                columnWidthMap={columnWidthMap}
                                columnHeaderElementIds={columnHeaderElementIds}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'tableDataRowWrapper' && 
                    TableDataRowWrapperViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TableDataRowWrapperViewer
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listKeyTracker={listKeyTracker}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                                columnCellElementIds={columnCellElementIds}
                                tableData={tableData}
                                columnHeaderElementIds={columnHeaderElementIds}
                                columnWidthMap={columnWidthMap}
                                triggerScrollQuery={triggerScrollQuery}
                                queryOnScroll={queryOnScroll}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    [
                        'tableHeaderRow', 'tableFilterRow', 
                        'tableDataRow'
                    ].includes(elementType) && 
                    TableRowViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TableRowViewer
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                                columnHeaderElementIds={columnHeaderElementIds}
                                columnFilterElementIds={columnFilterElementIds}
                                columnCellElementIds={columnCellElementIds}
                                rowType={rowType}
                                columnWidthMap={columnWidthMap}
                                updateTableHeaderWidth={updateTableHeaderWidth}
                                tableColumnBalanced={tableColumnBalanced}
                                updateTableSort={updateTableSort}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    [
                        'columnHeader'
                    ].includes(elementType) && 
                    TableHeaderCellViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TableHeaderCellViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                activeTabPositionField={activeTabPositionField}
                                tabCountField={tabCountField}
                                columnWidthMap={columnWidthMap}
                                updateTableHeaderWidth={updateTableHeaderWidth}
                                tableColumnBalanced={tableColumnBalanced}
                                updateTableSort={updateTableSort}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'table' && 
                    TableViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TableViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'tabWrapper' && 
                    TabWrapperViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TabWrapperViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    ['textBlock', 'blockQuote'].includes(elementType) && 
                    TextViewer && 
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TextViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'timer' && 
                    TimerViewer && 
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <TimerViewer
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'paginationWrapper' && 
                    PaginationWrapperViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <PaginationWrapperViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                                paginationDisplayLimitOptions={paginationDisplayLimitOptions}
                                pageIndex={pageIndex}
                                paginationPageCount={paginationPageCount}
                                paginationDisplayLimit={paginationDisplayLimit}
                                updatePaginationField={updatePaginationField}
                                changePageIndex={changePageIndex}
                                updateTableSort={updateTableSort}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'displayLimitSelector' && 
                    DisplayLimitSelectorViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <DisplayLimitSelectorViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                        bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                            bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                            handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                    lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                    updatedListIndexes={this.props.updatedListIndexes}
                            listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                        listItemPathId={this.props.listItemPathId}
                                    getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem} 
                                paginationDisplayLimitOptions={paginationDisplayLimitOptions}
                                paginationDisplayLimit={paginationDisplayLimit}
                                updatePaginationField={updatePaginationField}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'listPageCountText' && 
                    ListPageCountTextViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ListPageCountTextViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                paginationPageCount={paginationPageCount}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
                {
                    elementType === 'listIndexInput' && 
                    ListIndexInputViewer &&
                    <ErrorBoundary>
                        <Suspense fallback={<React.Fragment></React.Fragment>}>
                            <ListIndexInputViewer 
                                parentElementId={parentElementId}
                                parentElementModeFunctions={parentElementModeFunctions}
                                appId={appId}
                                historyPushFunction={historyPushFunction}
                                appEnvironment={appEnvironment}
                                workerInstance={workerInstance}
                                            bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                workerInstanceProp={this.props.workerInstanceProp}
                                bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                        appLanguage={this.props.appLanguage}
                                setAppDataFunction={setAppDataFunction}
                                setElementAppData={setElementAppData}
                                setParentAppData={setParentAppData}
                                elementId={elementId}
                                parentDisplayFlex={parentDisplayFlex}
                                elementPosition={elementPosition}
                                inList={inList}
                                        lowestUpdatedListIndex={this.props.lowestUpdatedListIndex}
                                        updatedListIndexes={this.props.updatedListIndexes}
                                listRowCount={this.props.listRowCount}
                                listItemIndex={listItemIndex}
                                            listItemPathId={this.props.listItemPathId}
                                        getListMetaData={this.props.getListMetaData}
                                listItemBaseIndex={listItemBaseIndex}
                                rowDataBaseIndex={rowDataBaseIndex}
                                listItemData={listItemData}
                                pathToListItem={pathToListItem}
                                pageIndex={pageIndex}
                                paginationPageCount={paginationPageCount}
                                updatePaginationField={updatePaginationField}
                            />
                        </Suspense>
                    </ErrorBoundary>
                }
            </React.Fragment>
        )
    }

    render(){
        return (
            <React.Fragment>
                {this.renderElementSwitch()}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state){
    return {
        // appMap : state.AppViewer.appMap,
        // batchComputeCache : state.AppViewer.batchComputeCache
        cachedUiComponents : state.UiComponent.cachedUiComponents,
        loadedComponents : state.UiComponent.loadedComponents,
    }
}

export default connect(mapStateToProps, {
    setUiComponentCache, triggerComponentLoad
})(RecursiveViewerElementUi);